import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: $props.url,
    class: _normalizeClass(["text-base", $props.textColor === '' ? 'text-secondary-500 dark:text-secondaryDark-white' : $props.textColor]),
    target: "_blank",
    rel: "noreferrer"
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}