export default class PsConfig {

    static versionNo : String = '1.3';

    ///
    /// API Key to connection with Main Backend
    /// This key must equal with key from backend
    ///
    static apiKey: String = "mazzadatiisthebest";

    ///
    /// Live Mode
    /// ------------
    /// If you want to upload to server, please enable below and comment Development below
    ///
    static fixedDomain: String = "https://cars.mazzadati.com/backend"; 
    static domain: String = "https://cars.mazzadati.com/backend"; 

    ///
    /// Development Mode
    /// -----------------
    /// Before upload to server, please comment this 2 lines.
    ///
    // static fixedDomain: String = "https://cars.mazzadati.com/backend/";    
    // static domain: String = "http://localhost:3000";

    ///
    /// Firebase Config
    ///
    static firebaseConfig = {
        apiKey: "AIzaSyA4ixwGQlEsth_zQ6qq3-5WcShJ9n_CmSc",
        authDomain: "mzcars.firebaseapp.com",
        projectId: "mzcars",
        storageBucket: "mzcars.appspot.com",
        messagingSenderId: "1026989377976",
        appId: "1:1026989377976:web:48d9698516380231f4274d",
        measurementId: "G-Z87CQSBKRT"            
    };
    
    // static firebaseConfig = {
    //     apiKey: "AIzaSyCCbP35UgAsfdnJaQM4TxWDLICYR4gDi28",
    //     authDomain: "flutter-admotors.firebaseapp.com",
    //     databaseURL: "https://flutter-admotors.firebaseio.com",
    //     projectId: "flutter-admotors",
    //     storageBucket: "flutter-admotors.appspot.com",
    //     messagingSenderId: "959099436187",
    //     appId: "1:959099436187:web:915d817bd3b509b1b81a3e"
    // };
    
    ///
    /// Base URL * Don't update it *
    ///
    static base: String = PsConfig.domain; 
    static baseUrl: String = PsConfig.domain + '/index.php';
    static fixedPath : String = PsConfig.fixedDomain;


    static mapKey: String = "AIzaSyA4ixwGQlEsth_zQ6qq3-5WcShJ9n_CmSc";
    
    /// 
    /// Debug Mode
    ///
    static isDebugMode : Boolean = true;

    /// Is Demo
    ///
    static is_demo : Boolean = true;

    static item_upload_ui1 : Boolean = true;

    static item_upload_ui2 : Boolean = false;

}
