
export default {
    name : 'PsLink',
    props: {
        textColor : {
            type: String,               
            default: 'text-secondary-500 dark:text-secondaryDark-white',                
        },
        url : {
            type : String
        }        
    }
}
