
export default {
    name : 'PsLabel',
    props: {
        textColor : {
            type: String,               
            default: 'text-secondary-500 dark:text-secondaryDark-white',            
        }        
    }
}
