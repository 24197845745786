
export default {
    name : 'PsLine',
    props: {
        textColor : {
            type: String,               
            default: 'text-primary-500 dark:text-textdark-500',            
        }        
    }
}
