import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Marker = _resolveComponent("Marker")!
  const _component_GoogleMap = _resolveComponent("GoogleMap")!

  return (_ctx.dataReady == true)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_GoogleMap, {
          id: "map",
          ref: "map_ref",
          "api-key": _ctx.map.key,
          center: _ctx.map.center,
          zoom: _ctx.map.zoom,
          style: {"width":"100%","height":"280px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Marker, {
              options: _ctx.mcenter,
              draggable: "false",
              ref: "marker",
              onDrag: _ctx.onChange
            }, null, 8, ["options", "onDrag"])
          ]),
          _: 1
        }, 8, ["api-key", "center", "zoom"])
      ]))
    : _createCommentVNode("", true)
}